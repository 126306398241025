import React from 'react';
import { Typography, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const BP1 = '@media (max-width: 1279px)';
const BP2 = '@media (max-width: 479px)';

const styles = {
	root: {
		width: '100%',
		maxWidth: '2560px',
		ml: 'auto',
		mr: 'auto',
		minHeight: '800px',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		display: 'flex',
		boxSizing: 'border-box',
		transition: 'all .3s',
		[BP1]: {
			minHeight: '600px',
		},
	},
	content: {
		width: '100%',
		maxWidth: '1128px',
		ml: 'auto',
		mr: 'auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		backgroundColor: 'transparent',
		boxSizing: 'border-box',
		p: '20px',
	},
	title: {
		transition: 'all 0.3s',
		overflowWrap: 'break-word',
		wordWrap: 'break-word',
		wordBreak: 'break-word',
		'-ms-hyphens': 'auto',
		'-moz-hyphens': 'auto',
		'-webkit-hyphens': 'auto',
		hyphens: 'auto',
		[BP1]: {
			fontSize: '50px',
			lineHeight: '56px',
		},
		[BP2]: {
			fontSize: '36px',
			lineHeight: '41px',
		},
	},
	subtitle: {
		marginBottom: '80px',
		transition: 'all 0.3s',
	},
	button: {
		width: '200px',
		height: '48px',
		fontFamily: 'Roboto',
		fontSize: '20px',
		borderRadius: '6px',
		marginTop: '80px',
	},
};

const Banner2 = ({
	title, subtitle, image, onClick,
}) => {
	const { t } = useTranslation(['home']);
	return (
		<Box sx={{ ...styles.root, backgroundImage: `url(${image})` }}>
			<Box sx={styles.content}>
				{title && (
					<Typography variant='bannerTitle' sx={styles.title}>{title}</Typography>
				)}
				{subtitle && (
					<Typography variant='bannerSubtitle' sx={styles.subtitle}>{subtitle}</Typography>
				)}
				<Button variant='contained' size='large' sx={styles.button} onClick={onClick}>{t('nextButton')}</Button>
			</Box>
		</Box>
	);
};

/* eslint-disable react/forbid-prop-types */
Banner2.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	image: PropTypes.any.isRequired,
	onClick: PropTypes.any.isRequired,
};

Banner2.defaultProps = {
	subtitle: '',
};

export default Banner2;
