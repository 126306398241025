import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import Guarantee from '../../components/Guarantee';
import prod1 from '../../assets/images/carousel/prod1.jpg';
import prod2 from '../../assets/images/carousel/prod2.jpg';
import prod3 from '../../assets/images/carousel/prod3.jpg';
import prevButton from '../../assets/images/back.svg';
import nextButton from '../../assets/images/forward.svg';
import SliderDots from '../../components/SliderDots';
import TextContainer from '../Home/components/TextContainer';
import spots3 from '../../assets/images/spots3.svg';

const BP2 = '@media (max-width: 1500px)';

const IMAGES = [prod1, prod2, prod3];

const styles = {
	topContainer: {
		width: '100%',
		pl: '16px',
		pr: '16px',
		backgroundColor: '#7A8F99',
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '0 32px',
		boxSizing: 'border-box',
		overflow: 'hidden',
		[BP2]: {
			pl: '0px',
			pr: '0px',
		},
	},
	carouselContainer: {
		width: '100%',
		height: '100%',
		maxWidth: '1128px',
		overflow: 'hidden',
		boxSizing: 'border-box',
	},
	carouselImageContainer: {
		maxWidth: '1128px',
		outline: 'none',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	navButton: {
		backgroundColor: '#FCFCFC',
		borderRadius: '3px',
		p: '8px',
		pb: '4px',
		boxShadow: '0px 1px 2px #677D8433',
		cursor: 'pointer',
		[BP2]: {
			display: 'none',
		},
	},
	lastSpot: {
		width: '100%',
		maxWidth: '2560px',
		minWidth: '290px',
		ml: 'auto',
		mr: 'auto',
		height: '230px',
		backgroundImage: `url(${spots3})`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '80%',
	},
};

const sliderConfig = (setCurrentSlide) => ({
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	vertical: false,
	arrows: false,
	autoplay: true,
	autoplaySpeed: 5000,
	adaptiveHeight: true,
	afterChange: setCurrentSlide,
});

const renderNavButton = (isNext, onClick) => (
	<Box sx={styles.navButton} onClick={onClick}>
		<img src={isNext ? nextButton : prevButton} alt={isNext ? 'Next' : 'Previous'} />
	</Box>
);

const Product = () => {
	const sliderRef = useRef(null);
	const [currentSlide, setCurrentSlide] = useState(0);
	const { t } = useTranslation(['products']);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Box sx={{ 	overflow: 'hidden' }}>
			<Box sx={styles.topContainer}>
				{renderNavButton(false, () => sliderRef.current.slickPrev())}
				<Box sx={styles.carouselContainer}>
					<Slider {...(sliderConfig(setCurrentSlide))} ref={sliderRef}>
						{IMAGES.map((image, i) => (
							<Box key={i.toString()} sx={styles.carouselImageContainer}>
								<img src={image} style={{ width: '100%' }} alt={`Carousel ${i + 1}`} />
							</Box>
						))}
					</Slider>
				</Box>
				{renderNavButton(true, () => sliderRef.current.slickNext())}
			</Box>
			<SliderDots currentSlide={currentSlide} totalCount={IMAGES.length} style={{ mt: '40px', mb: '40px' }} />
			<TextContainer title={t('title')} text={t('text')} style={{ pt: '0px' }} titleStyle={{ color: 'text.dark' }} />
			<Box sx={styles.lastSpot} />
			<Guarantee />
		</Box>
	);
};

export default Product;
