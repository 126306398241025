import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import branch1 from '../assets/images/branch1.svg';
import branch2 from '../assets/images/branch2.svg';
import badge1 from '../assets/images/badge1.svg';
import badge2 from '../assets/images/badge2.svg';

const BP1 = '@media (max-width: 719px)';
const BP2 = '@media (max-width: 539px)';
const BP3 = '@media (max-width: 359px)';

const styles = {
	root: {
		width: '100%',
		pl: '20px',
		pr: '20px',
		pt: '37px',
		pb: '37px',
		backgroundColor: 'primary.main',
		boxSizing: 'border-box',
	},
	content: {
		width: '100%',
		maxWidth: '936px',
		ml: 'auto',
		mr: 'auto',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	title: {
		fontFamily: 'roboto-bold',
		color: '#FFF',
		fontSize: '30px',
		lineHeight: '30px',
		letterSpacing: '3px',
		marginBottom: '8px',
		textAlign: 'center',
		transition: 'all .3s',
		[BP1]: {
			fontSize: '24px',
			lineHeight: '24px',
		},
	},
	fancyContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		[BP2]: {
			justifyContent: 'center',
		},
	},
	gContainer: {
		display: 'flex',
		flexDirection: 'row',
		height: '50px',
	},
	gText: {
		fontFamily: 'roboto-bold',
		color: '#FFF',
		fontSize: '22px',
		letterSpacing: '2px',
		backgroundColor: 'background.dark',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		minWidth: '270px',
		textAlign: 'center',
		transition: 'all .3s',
		[BP1]: {
			fontSize: '16px',
			minWidth: '200px',
		},
	},
	branch: {
		width: '100%',
		height: '74px',
		maxWidth: '200px',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		[BP2]: {
			display: 'none',
		},
	},
	description: {
		fontFamily: 'roboto-bold',
		color: '#FFF',
		fontSize: '19px',
		lineHeight: '19px',
		letterSpacing: '2px',
		width: '100%',
		textAlign: 'center',
		marginTop: '20px',
		[BP1]: {
			fontSize: '16px',
			lineHeight: '16px',
		},
	},
	badge: {
		[BP3]: {
			display: 'none',
		},
	},
};

const Guarantee = () => {
	const { t } = useTranslation();
	return (
		<Box sx={styles.root}>
			<Box sx={styles.content}>
				<Box sx={styles.title}>{t('guarantee.title').toUpperCase()}</Box>
				<Box sx={styles.fancyContainer}>
					<Box sx={{ ...styles.branch, backgroundImage: `url(${branch1})` }} />
					<Box sx={styles.gContainer}>
						<Box sx={{ ...styles.badge,  marginRight: '-2px' }}><img src={badge1} alt='Badge' /></Box>
						<Typography sx={styles.gText}>{t('guarantee.guarantee').toUpperCase()}</Typography>
						<Box sx={{ ...styles.badge,  marginLeft: '-2px' }}><img src={badge2} alt='Badge' /></Box>
					</Box>
					<Box sx={{ ...styles.branch, backgroundImage: `url(${branch2})` }} />
				</Box>
				<Typography sx={styles.description}>{t('guarantee.description').toUpperCase()}</Typography>
			</Box>
		</Box>
	);
};

export default Guarantee;
