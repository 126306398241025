import React, { forwardRef, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import spots1 from '../../../assets/images/spots1.svg';

const BP1 = '@media (max-width: 589px)';

const styles = {
	root: {
		backgroundColor: '#FFF',
		paddingLeft: '20px',
		paddingRight: '20px',
		paddingTop: '150px',
		paddingBottom: '150px',
	},
	content: {
		width: '100%',
		maxWidth: '1128px',
		ml: 'auto',
		mr: 'auto',
		display: 'flex',
		flexDirection: 'column',
	},
	respText: {
		overflowWrap: 'break-word',
		wordWrap: 'break-word',
		wordBreak: 'break-word',
		'-ms-hyphens': 'auto',
		'-moz-hyphens': 'auto',
		'-webkit-hyphens': 'auto',
		hyphens: 'auto',
	},
	title: {
		width: '100%',
		fontFamily: 'roboto-bold',
		fontSize: '50px',
		lineHeight: '50px',
		color: 'primary.main',
		letterSpacing: '-1px',
		marginBottom: '40px',
		transition: 'all .3s',
		[BP1]: {
			fontSize: '36px',
			lineHeight: '36px',
		},
	},
	textContainer: {
		maxWidth: '1128px',
		marginBottom: '40px',
	},
	text: {
		fontFamily: 'roboto-light',
		width: '100%',
		fontSize: '28px',
		lineHeight: '40px',
		color: 'text.dark',
		transition: 'all .3s',
		[BP1]: {
			fontSize: '24px',
			lineHeight: '34px',
		},
	},
	image: {
		width: '50px',
		marginBottom: '20px',
	},
	bottomDot: {
		width: '12px',
		height: '12px',
		backgroundColor: 'primary.main',
		borderRadius: '50%',
		alignSelf: 'flex-start',
	},
};

// eslint-disable-next-line react/display-name
const TextContainer = forwardRef(({
	title, text, topImage, style, titleStyle, textStyle,
}, ref) => {
	const [rows, setRows] = useState(text.split('\n'));

	useEffect(() => {
		setRows(text.split('\n'));
	}, [text]);

	return (
		<Box sx={{ ...styles.root, ...style }}>
			<Box sx={styles.content}>
				<img src={topImage} style={styles.image} alt='Spots' />
				<Box sx={styles.textContainer}>
					<Typography ref={ref} sx={{ ...styles.title, ...styles.respText, ...titleStyle }}>{title}</Typography>
					{rows.map((row, i) => (
						<React.Fragment key={i.toString()}>
							<Typography sx={{ ...styles.text, ...styles.respText, ...textStyle }}>{row || <br />}</Typography>
						</React.Fragment>
					))}
				</Box>
				<Box sx={styles.bottomDot} />
			</Box>
		</Box>
	);
});

/* eslint-disable react/forbid-prop-types */
TextContainer.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	topImage: PropTypes.any,
	style: PropTypes.any,
	textStyle: PropTypes.any,
	titleStyle: PropTypes.any,
};

TextContainer.defaultProps = {
	topImage: spots1,
	style: {},
	textStyle: {},
	titleStyle: {},
};

export default TextContainer;
