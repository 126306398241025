import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider, Box } from '@mui/material';
import {
	BrowserRouter as Router,
	Switch,
	Route, Redirect,
} from 'react-router-dom';
import routes from './navigation/routes';
import Header from './components/Header';
import Footer from './components/Footer';
import i18n from './lib/i18n/i18n';

const BP1 = '@media (max-width: 1069px)';

const COLORS = {
	primary: '#0D988C',
	darkText: '#0F2733',
	lightText: '#F0F3F5',
};

const theme = createTheme({
	typography: {
		fontFamily: 'roboto',
		bannerTitle: {
			fontFamily: 'roboto-bold',
			fontSize: '80px',
			lineHeight: '90px',
			color: COLORS.lightText,
			letterSpacing: '-1.6px',
		},
	},
	palette: {
		primary: {
			main: COLORS.primary,
		},
		text: {
			primary: COLORS.primary,
			dark: COLORS.darkText,
			light: COLORS.lightText,
		},
		background: {
			dark: COLORS.darkText,
		},
	},
	components: {
		MuiTypography: {
			defaultProps: {
				variantMapping: {
					body1: 'div',
					bannerTitle: 'div',
					bannerSubtitle: 'div',
				},
			},
		},
	},
});

const styles = {
	root: {
		position: 'relative',
		boxSizing: 'border-box',
		width: '100%',
	},
	content: {
		width: '100%',
		paddingTop: '164px',
		transition: 'all .3s',
		[BP1]: {
			paddingTop: '104px',
		},
	},
};

const App = () => {
	const [headerShrunk, setHeaderShrunk] = useState(false);

	useEffect(() => {
		(async () => {
			const lang = localStorage.getItem('lang');
			if (lang === 'hu' || lang === 'ro') {
				await i18n.changeLanguage(lang);
			}
		})();
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<Box sx={styles.root}>
				<Router>
					<Header shrunk={headerShrunk} onChange={setHeaderShrunk} />
					<Box sx={styles.content}>
						<Switch>
							{routes.map(({ path, component, exact }, i) => (
								<Route
									key={i.toString()}
									path={path}
									component={component}
									exact={exact}
								/>
							))}
							<Route path='*'>
								<Redirect to='/' />
							</Route>
						</Switch>
					</Box>
				</Router>
				<Footer />
			</Box>
		</ThemeProvider>
	);
};

export default App;
