import React, { useEffect, useState } from 'react';
import { Box, Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import logo from '../assets/images/logo.svg';
import i18n from '../lib/i18n/i18n';
import collapseIcon from '../assets/images/collapse_icon.svg';
import LanguageSelector from './LanguageSelector';
import menuIcon from '../assets/images/menu.svg';

const BP1 = '@media (max-width: 1069px)';
const BP2 = '@media (max-width: 599px)';

const styles = {
	root: {
		position: 'fixed',
		zIndex: 100,
		width: '100%',
		gap: '0 20px',
		boxShadow: '0px 50px 80px #0D988C1A',
		transition: 'all .3s',
		backgroundColor: '#FFF',
		padding: '20px',
		boxSizing: 'border-box',
	},
	content: {
		ml: 'auto',
		mr: 'auto',
		width: '100%',
		minWidth: '104px',
		maxWidth: '1128px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: '0 20px',
	},
	logo: {
		width: '100%',
		maxWidth: '200px',
		transition: 'all .3s',
		cursor: 'pointer',
		[BP1]: {
			maxWidth: '100px',
		},
	},
	logoShrunk: {
		maxWidth: '100px',
	},
	btnContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		[BP2]: {
			display: 'none',
		},
	},
	btn: {
		fontFamily: 'roboto-bold',
		fontSize: '20px',
		color: '#0F2733',
		cursor: 'pointer',
		letterSpacing: '-0.4px',
	},
	menuIcon: {
		flexBasis: '50px',
		display: 'none',
		cursor: 'pointer',
		minWidth: '40px',
		width: '50px',
		[BP2]: {
			display: 'block',
		},
	},
	mobileMenuContainer: {
		width: '100%',
		position: 'fixed',
		top: 0,
		left: 0,
		zIndex: 200,
		boxShadow: '0px 2px 10px #7A8F9933',
	},
	collapseIcon: {
		width: '50px',
		cursor: 'pointer',
	},
	mobileMenu: {
		width: '100%',
		backgroundColor: '#FFF',
		padding: '20px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		boxSizing: 'border-box',
		gap: '20px',
	},
	languageSelector: {
		position: 'absolute',
		right: '80px',
		[BP2]: {
			display: 'none',
		},
	},
};

const Header = ({ shrunk, onChange }) => {
	const [menuOpened, setMenuOpened] = useState(false);
	const [language, setLanguage] = useState(i18n.language);
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		if (menuOpened) {
			const f = () => setMenuOpened(false);
			window.addEventListener('resize', f);
			return () => {
				window.removeEventListener('resize', f);
			};
		}
		return () => {};
	}, [menuOpened]);

	useEffect(() => {
		setLanguage(i18n.language);
	}, [t]);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const handleScroll = () => {
				onChange(window.pageYOffset > 0);
			};
			window.addEventListener('scroll', handleScroll);
			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		}
		return () => {};
	}, [onChange]);

	const changeLanguage = async (lang) => {
		await i18n.changeLanguage(lang);
		localStorage.setItem('lang', lang);
	};

	const handleMenu = (e) => {
		e.stopPropagation();
		setMenuOpened((state) => !state);
	};

	const handleNavigation = (path) => {
		setMenuOpened(false);
		if (location.pathname !== path) history.push(path);
	};

	const handleHome = () => handleNavigation('/');
	const handleProducts = () => handleNavigation('/products');

	return (
		<>
			<Box sx={styles.root}>
				<Box sx={styles.content}>
					<Box sx={{ ...styles.logo, ...(shrunk ? styles.logoShrunk : {}) }} onClick={handleHome}>
						<img src={logo} style={{ width: '100%' }} alt='Logo' />
					</Box>
					<Box sx={styles.btnContainer}>
						<Box sx={{ ...styles.btn, marginRight: '36px' }} onClick={handleHome}>{t('about')}</Box>
						<Box sx={styles.btn} onClick={handleProducts}>{t('products')}</Box>
						<LanguageSelector language={language} onChange={changeLanguage} style={{ marginLeft: '50px' }} />
					</Box>
					<Box sx={styles.menuIcon} onClick={handleMenu}>
						<img src={menuIcon} style={{ width: '100%' }} alt='Collapse icon' />
					</Box>
				</Box>
			</Box>
			<Box sx={styles.mobileMenuContainer}>
				<Collapse in={menuOpened} timeout='auto' unmountOnExit>
					<Box sx={styles.mobileMenu}>
						<Box sx={styles.collapseIcon} onClick={handleMenu}>
							<img style={{ width: '100%' }} src={collapseIcon} alt='Menu icon' />
						</Box>
						<Box sx={styles.btn} onClick={handleHome}>{t('about')}</Box>
						<Box sx={styles.btn} onClick={handleProducts}>{t('products')}</Box>
						<LanguageSelector language={language} onChange={changeLanguage} style={{ marginTop: '20px' }} />
					</Box>
				</Collapse>
			</Box>
		</>
	);
};

/* eslint-disable react/forbid-prop-types */
Header.propTypes = {
	shrunk: PropTypes.any.isRequired,
	onChange: PropTypes.any.isRequired,
};

export default Header;
